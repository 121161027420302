import { eventId } from '../../config';

import { queryApi } from '../../Services/queryApi';

const getLeadsArray = (user) => {
  return async (dispatch, getState) => {
    const leadsArray = await dispatch(
      queryApi.endpoints.getLeads.initiate(eventId)
    );

    let filteredData = [];

    if (user.exhibitor_lead_retrieval === 'View Own') {
      for (let i = 0; i < leadsArray.data.length; i++) {
        if (leadsArray.data[i].resource_type === 'Exhibit') {
          filteredData.push(leadsArray.data[i]);
        } else {
          // CONDITIONAL CHECK if no contacts somehow... set equal to empty array
          if (!leadsArray.data[i]?.contacts?.length) {
            leadsArray.data[i].contacts = [];
          }

          for (let j = 0; j < leadsArray.data[i].contacts.length; j++) {
            if (leadsArray.data[i].contacts[j].email === user.email) {
              filteredData.push(leadsArray.data[i]);
            }
          }
        }
      }
    } else {
      filteredData = leadsArray.data;
    }

    let arrayCSV = filteredData.map((event) => ({
      'Account Owner': event.resource_owner,
      Action: event.action,
      Type: event.resource_type,
      'Resource Name': event.resource_name,
      'User Account': event.visitor_account_name,
      Email: event.visitor_email,
      'First Name': event.visitor_first_name,
      'Last Name': event.visitor_last_name,
      'Job Title': event.visitor_title,
      Department: event.visitor_department,
      Site: event.visitor_site,
      'Address 1': event.visitor_street,
      'Address 2': null,
      City: event.visitor_city,
      'Zip Code': '=""' + event.visitor_zip + '""',
      State: event.visitor_state,
      Country: event.visitor_country,
      'Time Visited': event.visit_time,
    }));

    let users = [];

    filteredData.map((event) => {
      let presence = users
        .map((user) => user.email)
        .indexOf(event.visitor_email);

      if (presence === -1) {
        if (event.resource_name === 'Exhibitor Lead Retrieval') {
          users.push({
            firstName: event.visitor_first_name,
            lastName: event.visitor_last_name,
            email: event.visitor_email,
            company: event.visitor_account_name,
            title: event.visitor_title,
            site: event.visitor_site,
            department: event.visitor_department,
            inPersonLead: true,
            actions: [
              {
                action: event.action,
                visited: event.url_visited,
                type: event.resource_type,
                name: event.resource_name,
                id: event.resource_id,
                createdAt: event.visit_time,
              },
            ],
          });
        } else {
          users.push({
            firstName: event.visitor_first_name,
            lastName: event.visitor_last_name,
            email: event.visitor_email,
            company: event.visitor_account_name,
            title: event.visitor_title,
            site: event.visitor_site,
            department: event.visitor_department,
            inPersonLead: false,
            actions: [
              {
                action: event.action,
                visited: event.url_visited,
                type: event.resource_type,
                name: event.resource_name,
                id: event.resource_id,
                createdAt: event.visit_time,
              },
            ],
          });
        }
      } else {
        if (event.resource_name === 'Exhibitor Lead Retrieval') {
          users[presence].inPersonLead = true;
        }

        users[presence].actions.push({
          action: event.action,
          visited: event.url_visited,
          type: event.resource_type,
          name: event.resource_name,
          id: event.resource_id,
          createdAt: event.visit_time,
        });
      }

      return null;
    });

    return {
      leadsArray: arrayCSV,
      leadsReporting: filteredData,
      leadsArrayObjects: users,
    };
  };
};

export default getLeadsArray;
