import React, { Component } from 'react';
// import axios from 'axios';
// import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
// import PubNubReact from 'pubnub';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';

// table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { eventId } from '../../config';

// reporting components
import Reporting from './Reporting.Leads';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getChatMessages from '../../Store/Actions/getChatMessages';
import addChatSubscription from '../../Store/Actions/addChatSubscription';
import setChatMembership from '../../Store/Actions/setChatMembership';
import selectActivePrivateChat from '../../Store/Actions/selectActivePrivateChat';
import setChatbar from '../../Store/Actions/setChatbar';
import viewPrivateChat from '../../Store/Actions/viewPrivateChat';
import trackAction from '../../Store/Actions/tracker';
import { setChatWindow } from '../../Store/Reducers/twilio';
import twilioCreateJoinOneOnOneConversation from '../../Store/Actions/twilioCreateJoinOneOnOneConversation';
import getLeadsArray from '../../Store/Actions/getLeadsArray';

// Utils
import getTwilioOneOnOneConversation from '../../Utilities/getTwilioOneOnOneConversation';

// Download to CSV
import { CSVLink } from 'react-csv';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  downloadLink: {
    textDecoration: 'none',
  },
  downloadButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    margin: 'auto',
    '& .MuiButton-textSizeLarge': {
      size: 'large',
    },
  },
  downloadButtonContainer: {
    paddingLeft: '16px',
    // intentionally using 'sm' below
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingTop: theme.spacing(2),
    },
  },
  chatButtonContainer: {
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
});

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadsArray: [],
      leadsArrayObjects: [],
      leadsReporting: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.props
      .getLeadsArray(this.props.user.user)
      .then(({ leadsArray, leadsReporting, leadsArrayObjects }) => {
        this.setState({
          leadsArray,
          leadsReporting,
          leadsArrayObjects,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (this.props.debug.debug) {
          alert(`Error: Could not display Leads Report. ${error}`);
        }
      });

    const event = {
      action: 'Viewed',
      resource_type: 'Leads',
      resource_id: null,
      url_visited: '/leads',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }
  render() {
    const { classes } = this.props;

    console.log(this.state);

    const allRoutes = JSON.parse(
      this.props.event.event.available_routes
    ).routes;
    let pageTitle = '';
    allRoutes.forEach((route) => {
      if (route.route === '/leads' && route.title !== 'Divider') {
        pageTitle = route.title;
      }
    });

    let contentLoading = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading Leads...
        </Typography>
      </Grid>
    );

    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <br />
        <br />
        <Typography variant="h4" color="textPrimary">
          No Leads Found
        </Typography>
      </Grid>
    );

    let inPersonLeads = this.state.leadsArrayObjects
      .filter((lead) => lead.inPersonLead)
      .map((lead, index) => {
        return (
          <Accordion key={index} className={classes.root}>
            <AccordionSummary
              className={classes.expansionPanelMain}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="h6" component="div">
                {lead.firstName + ' ' + lead.lastName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" align="left">
                    {lead.email ? (
                      <React.Fragment>
                        <b>Email:</b> {lead.email}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {lead.company ? (
                      <React.Fragment>
                        <b>Company:</b> {lead.company}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {lead.title ? (
                      <React.Fragment>
                        <b>Title:</b> {lead.title}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {lead.site ? (
                      <React.Fragment>
                        <b>Site:</b> {lead.site}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {lead.department ? (
                      <React.Fragment>
                        <b>Department:</b> {lead.department}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      });

    let contentLoaded = this.state.leadsArrayObjects.map((lead, index) => {
      return (
        <Accordion key={index} className={classes.root}>
          <AccordionSummary
            className={classes.expansionPanelMain}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6" component="div">
              {lead.firstName + ' ' + lead.lastName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" align="left">
                  {lead.email ? (
                    <React.Fragment>
                      <b>Email:</b> {lead.email}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {lead.company ? (
                    <React.Fragment>
                      <b>Company:</b> {lead.company}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {lead.title ? (
                    <React.Fragment>
                      <b>Title:</b> {lead.title}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {lead.site ? (
                    <React.Fragment>
                      <b>Site:</b> {lead.site}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
                <Typography variant="body1" align="left">
                  {lead.department ? (
                    <React.Fragment>
                      <b>Department:</b> {lead.department}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </Typography>
              </Grid>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Time</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lead.actions.map((companyLeads, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell align="center">
                              {companyLeads.name}
                            </TableCell>
                            <TableCell align="center">
                              {companyLeads.type}
                            </TableCell>
                            <TableCell align="center">
                              {`${moment(companyLeads.createdAt).format(
                                'MMMM Do, h:mm a'
                              )}`}
                            </TableCell>
                            <TableCell align="center">
                              {companyLeads.action}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });

    let renderInPersonLeadsComponent = false;
    let bodyContent = contentLoading;
    if (!this.state.isLoading) {
      if (this.state.leadsArrayObjects.length === 0) {
        bodyContent = noResultsFound;
      } else {
        bodyContent = contentLoaded;
      }
    }

    return (
      <React.Fragment>
        <Grid container className={classes.header}>
          <Grid item xs={12} md={9}>
            <Paper>
              <Typography
                variant="h4"
                align="center"
                style={{ padding: '12px' }}
              >
                Your {pageTitle}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} className={classes.downloadButtonContainer}>
            <CSVLink
              data={this.state.leadsArray}
              filename={'my-leads.csv'}
              className={classes.downloadLink}
              target="_blank"
            >
              <Button
                variant="contained"
                size="large"
                className={classes.downloadButton}
              >
                <GetAppIcon style={{ marginRight: '8px' }} />
                Download {pageTitle}
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper>
            <Typography variant="h4" align="center" style={{ padding: '12px' }}>
              In Person Leads
            </Typography>
          </Paper>
        </Grid>
        {inPersonLeads}
        <Grid item xs={12} md={12}>
          <Paper>
            <Typography variant="h4" align="center" style={{ padding: '12px' }}>
              Virtual Leads
            </Typography>
          </Paper>
        </Grid>
        {bodyContent}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // getChatMessages: getChatMessages,
      // addChatSubscription: addChatSubscription,
      // setChatMembership: setChatMembership,
      // selectActivePrivateChat: selectActivePrivateChat,
      // setChatbar: setChatbar,
      // viewPrivateChat: viewPrivateChat,
      // setChatWindow,
      // twilioCreateJoinOneOnOneConversation,
      trackAction: trackAction,
      getLeadsArray: getLeadsArray,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    chat: state.chat,
    debug: state.debug,
    event: state.event,
    conversations: state.twilio.conversations,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Leads));
