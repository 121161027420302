import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DataTable } from '../../Utilities/DataTable';
import { exhibitorGridOptions } from './ExhibitorGridOptions';
import clsx from 'clsx';
import axios from 'axios';
import { eventId } from '../../config';
import { useSelector } from 'react-redux';
import { useGetExhibitsQuery } from '../../Services/queryApi';

// Translations
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  centerButtons: {
    textAlign: 'center',
    lineHeight: 1.25,
  },
  toolbar: {
    minHeight: '64px', //required to make mobile behave properly
  },
}));

export default function ExhibitorList(props) {
  const classes = useStyles();
  const [exhibitorList, setExhibitorList] = useState([]);
  // access redux to get strapi
  const strapiPagesRedux = useSelector((state) => state.strapi);
  const eventInformation = useSelector((state) => state.event);
  // configure menu item

  const { data, error, isLoading } = useGetExhibitsQuery(eventId);

  // translation
  const { t } = useTranslation();

  useEffect(() => {
    // console.log(
    //   '🚀 ~ file: ExhibitorList.js ~ line 48 ~ useEffect ~ data',
    //   data
    // );

    if (data) {
      let exhibitors = data.data
        .map((exhibitor) => {
          switch (exhibitor?.booth_type) {
            case '0':
              return { ...exhibitor, sponsorship_level: 'Signature' };
            case '1':
              return { ...exhibitor, sponsorship_level: 'Diamond' };
            case '2':
              return { ...exhibitor, sponsorship_level: 'Platinum' };
            case '3':
              return { ...exhibitor, sponsorship_level: 'Gold' };
            case '4':
              return { ...exhibitor, sponsorship_level: 'Silver' };
            case '5':
              return { ...exhibitor, sponsorship_level: 'Booth' };
            case '6':
              return { ...exhibitor, sponsorship_level: 'Tabletop' };
            case '7':
              return { ...exhibitor, sponsorship_level: 'Bronze' };
            default:
              return { ...exhibitor, sponsorship_level: '' };
          }
        })
        .filter((exhibitor) => exhibitor?.sponsorship_level !== '');

      setExhibitorList(exhibitors);
    }
  }, [data]);

  //   return <div>Hello Exhibitor List</div>;
  return (
    <>
      <Typography variant="h4" style={{ marginBottom: '16px' }}>
        {t('landing.exhibitorList')}
      </Typography>
      <DataTable
        initialGridOptions={exhibitorGridOptions}
        initialRowData={exhibitorList}
        //   actionButtons={StaffGridActionButtons}
        type="exhibitor"
        setDeleting={(e) => this.handleDeletingChange(e, 'physical')}
        setEditing={(e) => this.handleEditingChange(e)}
        setCurrentPage={(e) => this.setcurrentStaff(e)}
      />
    </>
  );
}
